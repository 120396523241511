import { NavBar } from "@portal-frontend-ssr/navbar";
import { Outlet } from "@remix-run/react";
import { useLoaderData } from "@remix-run/react";
import { gameLayoutLoader } from "~/shared/loaders/gameLayoutLoader";

// Since not all cs routes start with /cs, we define the layout as a nested route without any path segment.
// https://remix.run/docs/en/main/file-conventions/routes#nested-layouts-without-nested-urls

export const loader = async () => {
  return await gameLayoutLoader({ gameId: "cs" });
};

export const Component = () => {
  const { nav, tournamentTimelineData } = useLoaderData<typeof loader>();

  return (
    <>
      <NavBar navbarItems={nav.items} tournamentTimeline={tournamentTimelineData} />
      <Outlet />
    </>
  );
};

export default Component;
